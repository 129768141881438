import { useEffect, useRef, useState } from "react";
import premierLogo from "../../../assets/images/RE.png";
import paypalImg from "../../../assets/images/Screenshot_2024-08-15_123126-removebg-preview.png";
import "./style.css";
import SignaturePad from "react-signature-pad-wrapper";

export default function ProposalAgreement({
  newEstate,
  data,
  selectedPackege,
  setStanderGutter,
  standerGutter,
  setGuardGutter,
  guardGutter,
}) {
  const sendData = async (e) => {
    await fetch("https://camp-coding.site/primiers/api/real_states/send_mail", {
      method: "POST",
      body: JSON.stringify({
        name: data?.user_name,
        email: person?.email,
        id: data?.id,
        phone: e.target.value,
      }),
      headers: {
        "Content-Type": "Application/json",
      },
    });
  };
  const [person, setPerson] = useState({});
  const paypalRef = useRef();

  useEffect(() => {
    const script = document.createElement("script");
    script.src = `https://www.paypal.com/sdk/js?client-id=AZgRUZh1UjvjJMOrUjc58z0iidOutzolwU1lr6Eu4FqL9U2NuHUlm4u4318ULavKhTlymHZszhGTVDr-`;
    script.async = true;
    script.onload = () => {
      window.paypal
        .Buttons({
          createOrder: (data, actions) => {
            return actions.order.create({
              purchase_units: [
                {
                  amount: {
                    value:
                      (selectedPackege?.price
                        ? parseFloat(selectedPackege?.price)
                        : 0) +
                      (standerGutter?.price
                        ? parseFloat(standerGutter?.price)
                        : 0) +
                      (guardGutter?.price
                        ? parseFloat(guardGutter?.price)
                        : 0) *
                        0.01,
                  },
                },
              ],
            });
          },
          onApprove: async (data, actions) => {
            const order = await actions.order.capture();
            console.log("Order", order);
          },
          onError: (err) => {
            console.error("PayPal Checkout onError", err);
          },
        })
        .render(paypalRef.current);
    };
    document.body.appendChild(script);
  }, []);

  return (
    <div className="proposal-container">
      <div className="proposal-agreement-header">
        <img src={premierLogo} />
        <p>
          Your <span>Proposal Agreements</span>
        </p>
        <img src={premierLogo} />
      </div>

      <div className="proposal-content-container">
        <h3>
          Verify your details <span>below</span>
        </h3>
        <p>ensure this represents your information and the project address</p>

        <form>
          <div>
            <label>
              Customer Name<span> (Required)</span>
            </label>
            <input type="text" value={data?.user_name} disabled />
          </div>

          <div>
            <label>
              Project Address<span> (Required)</span>
            </label>
            <input type="text" value={data?.location} disabled />
          </div>

          <div>
            <label>
              Email<span> (Required)</span>
            </label>
            <input
              type="email"
              value={person?.email}
              onChange={(e) => {
                setPerson({ ...person, email: e.target.value });
              }}
            />
          </div>

          <div>
            <label>
              Phone<span> (Required)</span>
            </label>
            <input
              type="number"
              onBlur={(e) => {
                sendData(e);
              }}
            />
          </div>
        </form>
      </div>

      <div className="proposal-content-container">
        <h3>
          <span>Your </span>proposal options
        </h3>
        <p>
          Select the options you would like below and digitally sign to lock in
          your place on the calendar
        </p>

        <h1
          style={{
            margin: "20px 0 0 0",
            color: "red",
            fontSize: "17px",
            padding: "0 10px 0 0",
            borderBottom: "4px solid",
            width: "fit-content",
          }}
        >
          Standard Gutters
        </h1>
        <div className="gutters proposal-content-images">
          {newEstate?.standers && newEstate?.standers?.length
            ? newEstate?.standers?.map((item) => {
                return (
                  <img
                    className={
                      item?.id == standerGutter?.id ? "selectedImage" : ""
                    }
                    src={item?.image}
                    alt=""
                    onClick={() => {
                      setStanderGutter(item);
                    }}
                  />
                );
              })
            : null}
        </div>
        <h1
          style={{
            margin: "20px 0 0 0",
            color: "red",
            fontSize: "17px",
            padding: "0 10px 0 0",
            borderBottom: "4px solid",
            width: "fit-content",
          }}
        >
          Gutters Guards
        </h1>
        <div className="gutters proposal-content-images">
          {newEstate?.guaters && newEstate?.guaters?.length
            ? newEstate?.guaters?.map((item) => {
                return (
                  <img
                    className={
                      item?.id == guardGutter?.id ? "selectedImage" : ""
                    }
                    src={item?.image}
                    alt=""
                    onClick={() => {
                      setGuardGutter(item);
                    }}
                  />
                );
              })
            : null}
        </div>

        <div className="proposal-investment-form">
          <h4>Total Investment</h4>
          <form>
            <div>
              <label>Good Roofing Option</label>
              <input type="text" value={selectedPackege?.title} disabled />
            </div>

            <div>
              <label>Standards Gutters</label>
              <input type="text" value={standerGutter?.price} disabled />
            </div>

            <div>
              <label>Gutters Guards</label>
              <input type="text" value={guardGutter?.price} disabled />
            </div>
          </form>
          <p>Note taxes are not included in proposal pricing</p>
        </div>
        <form>
          <div>
            <label>Total</label>
            <input
              type="text"
              value={
                (selectedPackege?.price
                  ? parseFloat(selectedPackege?.price)
                  : 0) +
                (standerGutter?.price ? parseFloat(standerGutter?.price) : 0) +
                (guardGutter?.price ? parseFloat(guardGutter?.price) : 0)
              }
              disabled
            />
          </div>
          <div>
            <label>Down Payment</label>
            <input
              type="text"
              value={
                ((selectedPackege?.price
                  ? parseFloat(selectedPackege?.price)
                  : 0) +
                  (standerGutter?.price
                    ? parseFloat(standerGutter?.price)
                    : 0) +
                  (guardGutter?.price ? parseFloat(guardGutter?.price) : 0)) *
                0.01
              }
              disabled
            />
          </div>
        </form>
        <div className="proposal-investment-form">
          <h4>Agreement Terms</h4>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Ab, odit
            expedita praesentium quam labore at eius minus autem harum quisquam
            quae veritatis cumque obcaecati consectetur velit, sapiente, iste
            fuga aperiam.
          </p>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquam
            repellendus harum iusto commodi aspernatur explicabo molestiae quas
            accusamus impedit maxime.
          </p>

          <form>
            <div>
              <label>
                Signature<span> (Required)</span>
              </label>
              <div
                className=""
                style={{
                  border: "3px dashed black",
                  height: "170px",
                  width: "min(800px,100%)",
                }}
              >
                <SignaturePad
                  options={{
                    minWidth: 5,
                    maxWidth: 10,
                    penColor: "#000",
                    height: "100%",
                  }}
                />
              </div>
            </div>
          </form>

          <p>
            By digitally signing this document you are entering into a binding
            contract with Premier Group to provide the aforementioned project as
            per the scope of the proposal and agreement terms
          </p>
        </div>

        <div className="proposal-date-signed">
          <label>Date signed</label>
          <span
            style={{
              padding: "2px 20px",
              border: "1px solid red",
              display: "inline-block",
              width: "fit-content",
              fontSize: "17px",
              color: "grey",
              margin: "10px 0",
              borderRadius: "27px",
            }}
          >
            {new Date().getDate()}-{new Date().getMonth() + 1}-
            {new Date().getFullYear()}
          </span>
        </div>

        <div className="proposal-paypal-img">
          <img src={paypalImg} />
        </div>
        <div>
          <label style={{ margin: "10px" }}>Total: </label>
          <input
            type="text"
            style={{
              padding: "4px 10px",
              borderRadius: "80px",
              border: "none",
            }}
            value={
              (selectedPackege?.price
                ? parseFloat(selectedPackege?.price)
                : 0) +
              (standerGutter?.price ? parseFloat(standerGutter?.price) : 0) +
              (guardGutter?.price ? parseFloat(guardGutter?.price) : 0)
            }
            disabled
          />
        </div>
        <div>
          <label style={{ margin: "10px" }}>Down Payment: </label>
          <input
            type="text"
            style={{
              padding: "4px 10px",
              borderRadius: "80px",
              border: "none",
            }}
            value={
              ((selectedPackege?.price
                ? parseFloat(selectedPackege?.price)
                : 0) +
                (standerGutter?.price ? parseFloat(standerGutter?.price) : 0) +
                (guardGutter?.price ? parseFloat(guardGutter?.price) : 0)) *
              0.01
            }
            disabled
          />
        </div>
        <div ref={paypalRef}></div>
      </div>
    </div>
  );
}
