import React, { useEffect, useRef, useState } from 'react';
import enhancyLogo from '../../../assets/images/Screenshot_2024-08-14_153452-removebg-preview.png';
import './style.css';

export default function Enhancify() {
  const [elementContent, setElementContent] = useState(null);
  const containerRef = useRef(null);

  useEffect(() => {

    const handleMutation = (mutationsList) => {
      for (let mutation of mutationsList) {
        if (mutation.type === 'childList') {
          const parentElement = document.querySelector("#paymentcalculatorwidget");
          if (parentElement && parentElement.shadowRoot) {
            const shadowRoot = parentElement.shadowRoot;


            console.log(shadowRoot);


            const referenceNode = shadowRoot.querySelector("script");


            if (containerRef.current && referenceNode) {
            referenceNode.querySelector("div").style.maxHeight = "200px"
              containerRef.current.insertBefore(parentElement, referenceNode);
            } else if (containerRef.current) {
              containerRef.current.appendChild(parentElement);
            }

            setElementContent(parentElement);
            break;
          }
        }
      }
    };


    const observer = new MutationObserver(handleMutation);


    observer.observe(document.body, { childList: true, subtree: true });


    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    if (elementContent && containerRef.current) {

      const container = containerRef.current;

      container.appendChild(elementContent);
    }
  }, [elementContent]);

  return (
    <div className='enhancify-containter' ref={containerRef} style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
      {/* <img src={clipPath} className='enhancy-clip-path'/> */}
      <div className="ehnacify-content">
        <img src={enhancyLogo} className='enhancify-logo' />
        <div className='enhancify-content-box'>
          <p>Easy financing options</p>
          <h5>Apply today fast and easy!</h5>
        </div>
      </div>
      {/* <img src={clipPathAbobe} className='enhancy-clip-path-above'/> */}
    </div>
  );
}
