import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import "./TestimonialsSection.css";
import testimonalLogoImg from '../../../assets/images/Screenshot_2024-08-14_115824-removebg-preview.png';
import premierLogo from '../../../assets/images/RE.png';
import measurementImg1 from '../../../assets/images/residental-home.webp';
import measurementImg2 from '../../../assets/images/Alon-Cohen-Residential.webp';
import measurementImg3 from '../../../assets/images/ResidentialRoofRepairs.webp';
import { Navigation } from "swiper/modules";

const testimonialImgs =  [{
  title: measurementImg1 ,
  id: 1
} , 
{
  title: measurementImg2,
  id:2
} , 
{
  title:measurementImg3,
  id:3
}]

const MeasurmentSection = ({ newEstate }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const swiperRef = useRef(null);

  const handleSlideChange = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      setActiveIndex(swiperRef.current.swiper.activeIndex);
      // console.log(swiperRef.current.swiper.activeIndex);
    }

  };

  return (
    <div className="measurement-installation-page">
    <div className="measurement-installation-page-container">
      <section className="measurement-installation-section">
        <div className="warrantly-container">
          <img src={testimonalLogoImg} />
          <p>A Warranty <br /> that <span>truly</span> <br /> <span>covers it</span></p>
        </div>
      </section>
      <div className="measurement-content-container">
        <div className="measure-down-background">
          <img src={premierLogo} />
          <p
            style={{
              margin: "140px auto 18px",
              width: "min(500px, 100%)",
              fontSize: "25px",
              color: "white",
              fontWeight: "900",
              textAlign: "center",
            }}
          >
            Your Precise Measurements & Project Details
          </p>
          <span style={{ color: "orangered", fontSize: "17px" }}>
            Peoject Address:
          </span>
          <p
            style={{
              margin: "0 auto",
              width: "min(500px, 100%)",
              fontSize: "16px",
              color: "darkgrey",
              fontWeight: "900",
              textAlign: "center",
            }}
          >
            {newEstate?.location}
          </p>
          <img src={premierLogo} />
        </div>
      </div>
    </div>
    
    <div className="measurement-imgs"> 
      {/* <div className="measurement-imgs-container"> */}
      <Swiper
          ref={swiperRef}
          onSlideChange={handleSlideChange}
          spaceBetween={30}
          navigation={true}
          slidesPerView={1}
          initialSlide={1}
          grabCursor={true}
          centeredSlides={true}
          
            breakpoints={{
              0: {
                slidesPerView: 1,
                spaceBetween: 80,
              },
              768: {
                slidesPerView: 1,
                spaceBetween: 80,
              },
              1024: {
                slidesPerView: 2.5,
                spaceBetween: 10,
              },
            }}
          autoplay={{ delay: 5000, disableOnInteraction: false }}
          modules={[Navigation]}
          
          className="mySwiper"
        >
          {newEstate?.images?.map((image , index) => (
            <SwiperSlide onClick={()=>window.open(image?.url, "_blanck")} style={{cursor:"pointer"}} key={image?.id} className='swiper-slide'>
            <div className={`measurement-testimonial-item ${index < activeIndex? "lesThanActive" : index > activeIndex ? "moreThanActive":""} `} >
            {/* <div className="testimonial-author"> */}\
                <img src={image?.url} alt="" />
              {/* </div> */}
            </div>
          </SwiperSlide>
          )) }

        </Swiper>
      {/* </div> */}
    </div>
    {/* <div className="measurement-imgs">
        <div className="measurement-imgs-container">
        <img src={measurementImg1} />
        <img src={measurementImg2} />
        <img src={measurementImg3} />
        </div>
      </div> */}
     </div>
  );
};

export default MeasurmentSection;
