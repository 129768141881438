import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import "./TestimonialsSection.css";
import premierLogo from "../../../assets/images/RE.png";

const InstallationSection = ({ newEstate }) => {
  return (
    <section className="installation-section-container">
      <div className="installation_container_custom">
        <div className="section-title text-center mb-5">
          <img src={premierLogo} />
          {/* <span>Watch Our</span> */}
          <h2 style={{ margin: "0 auto", fontSize: "33px" }}>
          Your Local Roofing Expert
          </h2>
          <img src={premierLogo} />
        </div>
        <h3>  </h3>
        <p
          style={{
            margin: "0 auto",
            width: "min(900px, 100%)",
            fontSize: "23px",
            color: "white",
          }}
        >
          With over 12 years of experience, we work in the commercial and
          residential property business, repairing, building, and maintaining
          properties nationwide. Our company is proud to be part of the NRCA,
          CRCA, BBB and ABC
        </p>
        <div className="video-wrapper">
          {console.log(newEstate)}
          <div className="video-background">
            <iframe
              className="video-frame"
              src={newEstate?.intallation_process}
              title="Installation Process"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </div>
        {/* <div className="section-title text-center mb-5">
          <span>Watch Our</span>
          <h2 style={{ margin: "0 auto", fontSize: "33px" }}>
            Installation Process
          </h2>
    
        </div>
        <div className="video-wrapper">
        <p
            style={{
              margin: "0 auto",
              width: "min(900px, 100%)",
              fontSize: "23px",
            }}
          >
            Learn how easy it is to set up and start using our product with our
            step-by-step installation guide. Watch the video below to see the
            entire process from start to finish.
          </p>
          <div className="video-background">
            <iframe
              className="video-frame"
              src={"https://www.youtube.com/embed/" + "dFqQCE02gjs"}
              title="Installation Process"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </div> */}
      </div>
    </section>
  );
};

export default InstallationSection;
